.input {
  border: 1px solid #ececec;
  padding: 4px;
  width: 100%;
}

.suggestionsContainerOpen {
  border: 1px solid #ececec;
  cursor: pointer;
  padding: 4px;
}

.suggestion {
  padding: 4px;
}
